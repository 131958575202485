.App {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    overflow: scroll;
}

.Preview {
    width: 1920px;
    height: 1080px;
    background-color: white;
    display: flex;
    font-family: 'Roboto', sans-serif;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    user-select: none;
    margin: 0 auto;
}

.Title {
    font-size: 140px;
    font-weight: 500;
    color: #424242;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.SubTitle {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: 60px;
    width: 800px;
    text-wrap: normal;
    text-align: center;
}

.Line {
    width: 100%;
    height: 2px;
    background-color: #E0E0E0;
    margin-bottom: 40px;
}

.Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.Control {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
    flex-wrap: wrap;
}

.Control input {
    width: 50%;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    color: #424242;
    font-weight: 500;
}

.toImage {
    margin-top: 20px;
    margin-bottom: 25px;
}

button {
    width: 300px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    color: #424242;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.2s ease-in-out;
}

button:hover {
    background-color: #E0E0E0;
}